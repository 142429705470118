var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LineChart", {
    attrs: {
      "chart-data": _vm.chartData,
      "chart-options": _vm.options,
      styles: _vm.styles,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }