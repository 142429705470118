var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.worker
    ? _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "my-5 ml-2 mr-4" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center pr-0",
                  attrs: { cols: "3" },
                },
                [
                  _c("b-avatar", {
                    staticClass: "mr-3",
                    staticStyle: { width: "64px", height: "64px" },
                  }),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#010C14", "font-size": "28px" },
                      },
                      [_vm._v(_vm._s(_vm.worker.name))]
                    ),
                    _c("div", { staticClass: "avo-text-normal-grey" }, [
                      _vm._v("Claims Worker"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.worker.assignments != undefined
                ? _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end pl-0",
                      attrs: { cols: "9" },
                    },
                    [
                      _c("div", {
                        staticClass: "mt-2 mr-2",
                        staticStyle: {
                          "padding-left": "10px",
                          "border-left": "4px solid var(--blue)",
                        },
                      }),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", [
                          _c("div", { staticClass: "stat-text" }, [
                            _vm._v(" " + _vm._s(_vm.worker.assignments) + " "),
                          ]),
                          _c("div", { staticClass: "avo-text-normal-grey" }, [
                            _vm._v("Assigned Claims"),
                          ]),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "mt-2 ml-4 mr-2",
                        staticStyle: {
                          "padding-left": "10px",
                          "border-left": "4px solid #FD4739",
                        },
                      }),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "stat-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.worker.issues_claims
                                  ? _vm.worker.issues_claims
                                  : 0
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "avo-text-normal-grey" }, [
                          _vm._v("Current Issues"),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "mt-2 ml-4 mr-2",
                        staticStyle: {
                          "padding-left": "10px",
                          "border-left": "4px solid #41B0A5",
                        },
                      }),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "stat-text" }, [
                          _vm._v(
                            " " + _vm._s(_vm.worker.practice_assignments) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "avo-text-normal-grey" }, [
                          _vm._v("Assigned Practices"),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "mt-2 ml-4 mr-2",
                        staticStyle: {
                          "padding-left": "10px",
                          "border-left": "4px solid #EEC05A",
                        },
                      }),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "stat-text" }, [
                          _vm._v(
                            " " + _vm._s(_vm.worker.code_assignments) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "avo-text-normal-grey" }, [
                          _vm._v("Assigned Codes"),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "mt-2 ml-4 mr-2",
                        staticStyle: {
                          "padding-left": "10px",
                          "border-left": "4px solid #AF3ECA",
                        },
                      }),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("div", { staticClass: "stat-text" }, [
                          _vm._v(
                            " " + _vm._s(_vm.worker.payer_assignments) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "avo-text-normal-grey" }, [
                          _vm._v("Assigned Payers"),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "b-nav",
                { staticClass: "nav-section", attrs: { tabs: "" } },
                [
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "WorkerSummary" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("WorkerSummary")
                        },
                      },
                    },
                    [_vm._v("Summary")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "WorkerClaims" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("WorkerClaims")
                        },
                      },
                    },
                    [_vm._v("Claims")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "WorkerIssues" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("WorkerIssues")
                        },
                      },
                    },
                    [_vm._v("Issues")]
                  ),
                  _vm.hasPermission("edit-worker-assignments")
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            active: _vm.activeTab == "WorkerAssigments",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTab("WorkerAssigments")
                            },
                          },
                        },
                        [_vm._v(" Assigned ")]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "WorkerTasks" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("WorkerTasks")
                        },
                      },
                    },
                    [_vm._v("Tasks")]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.activeTab == "WorkerWorklog" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("WorkerWorklog")
                        },
                      },
                    },
                    [_vm._v("Work Log")]
                  ),
                ],
                1
              ),
              _c(
                "b-container",
                [
                  _c(
                    "keep-alive",
                    [
                      _c(
                        _vm.activeTabComponent,
                        _vm._b(
                          { tag: "component", staticClass: "mt-4" },
                          "component",
                          _vm.activeTabProps,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }