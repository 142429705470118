var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "div",
        {
          staticClass: "mb-4",
          staticStyle: {
            background: "#E7EAEE",
            padding: "2px",
            "border-radius": "5px",
          },
        },
        [
          _c(
            "b-tabs",
            { attrs: { pills: "", fill: "" } },
            [
              _c("b-tab", {
                attrs: { title: "Practices" },
                on: {
                  click: function ($event) {
                    return _vm.setActiveTab(0)
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "Codes" },
                on: {
                  click: function ($event) {
                    return _vm.setActiveTab(1)
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "Payers" },
                on: {
                  click: function ($event) {
                    return _vm.setActiveTab(2)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-alert",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      _c("div", [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _vm.activeTab == 0
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", { attrs: { sm: "12", md: 7 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "b-input-group",
                              { staticClass: "search-group" },
                              [
                                _c("b-input-group-prepend", [
                                  _c(
                                    "span",
                                    { staticClass: "input-group-text" },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("b-form-input", {
                                  staticClass: "worker-search-input pl-1",
                                  attrs: {
                                    type: "search",
                                    placeholder: "Try typing a practice name",
                                  },
                                  on: {
                                    keyup: _vm.debouncePractices,
                                    search: _vm.debouncePractices,
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.activeTab == 1
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", { attrs: { sm: "12", md: 7 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "b-input-group",
                              { staticClass: "search-group" },
                              [
                                _c("b-input-group-prepend", [
                                  _c(
                                    "span",
                                    { staticClass: "input-group-text" },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("b-form-input", {
                                  staticClass: "worker-search-input pl-1",
                                  attrs: {
                                    type: "search",
                                    placeholder: "Try typing a cpt code",
                                  },
                                  on: {
                                    keyup: _vm.debounceCodes,
                                    search: _vm.debounceCodes,
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.activeTab == 2
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c("b-col", { attrs: { sm: "12", md: 7 } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c(
                              "b-input-group",
                              { staticClass: "search-group" },
                              [
                                _c("b-input-group-prepend", [
                                  _c(
                                    "span",
                                    { staticClass: "input-group-text" },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("b-form-input", {
                                  staticClass: "worker-search-input pl-1",
                                  attrs: {
                                    type: "search",
                                    placeholder: "Try typing a payer name",
                                  },
                                  on: {
                                    keyup: _vm.debouncePayers,
                                    search: _vm.debouncePayers,
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.worker-assignments-modal",
                      modifiers: { "worker-assignments-modal": true },
                    },
                  ],
                  staticClass: "avo-primary-btn",
                },
                [_vm._v("Update assignments")]
              ),
              _c("worker-assignments-update", {
                attrs: {
                  worker: _vm.worker,
                  practicesOptions: _vm.practicesOptions,
                  payersOptions: _vm.payersOptions,
                  payersGroupsOptions: _vm.payersGroupsOptions,
                },
                on: {
                  updated: function ($event) {
                    return _vm.refreshTables()
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _vm.activeTab == 0
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "avo-table-lrg-wrapper" },
                  [
                    _c("b-table", {
                      ref: "practicesTable",
                      staticClass: "avo-table-lrg",
                      attrs: {
                        id: "worker-practices-table",
                        "sort-desc": _vm.sortDesc,
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        items: _vm.getWorkerPractices,
                        fields: _vm.practicesFields,
                        busy: _vm.tableLoading,
                        "sticky-header": "90vh",
                        "primary-key": "practice_id",
                        "show-empty": "",
                        emptyHtml: _vm.emptyTableListMessage("practices"),
                        striped: "",
                      },
                      on: {
                        "update:sortDesc": function ($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function ($event) {
                          _vm.sortDesc = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "table-busy",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary my-2",
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle mr-2",
                                    }),
                                    _c("strong", [
                                      _vm._v("Fetching practices for you..."),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "cell(practice)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.name || "-") + " "),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(address)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.address || "-") + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(group_npi)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.group_npi || "-")),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(tax_id)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.tax_id || "-")),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(assigned_on)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(data.item.assigned_on)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1931043766
                      ),
                    }),
                  ],
                  1
                ),
                _vm.practices.length > 0
                  ? _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                        "aria-controls": "practicesTable",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.activeTab == 1
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "avo-table-lrg-wrapper" },
                  [
                    _c("b-table", {
                      ref: "codesTable",
                      staticClass: "avo-table-lrg",
                      attrs: {
                        id: "worker-codes-table",
                        "sort-desc": _vm.sortDesc,
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        items: _vm.getWorkerCodes,
                        fields: _vm.codesFields,
                        busy: _vm.tableLoading,
                        "sticky-header": "90vh",
                        "primary-key": "cpt_code",
                        "show-empty": "",
                        emptyHtml: _vm.emptyTableListMessage("codes"),
                        striped: "",
                      },
                      on: {
                        "update:sortDesc": function ($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function ($event) {
                          _vm.sortDesc = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "table-busy",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary my-2",
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle mr-2",
                                    }),
                                    _c("strong", [
                                      _vm._v("Fetching codes for you..."),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "cell(code)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.code || "-") + " "),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(description)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.long || "-") + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(assigned_on)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(data.item.assigned_on)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        861809808
                      ),
                    }),
                  ],
                  1
                ),
                _vm.codes.length > 0
                  ? _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                        "aria-controls": "codesTable",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.activeTab == 2
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "avo-table-lrg-wrapper" },
                  [
                    _c("b-table", {
                      ref: "payersTable",
                      staticClass: "avo-table-lrg",
                      attrs: {
                        id: "worker-payers-table",
                        "sort-desc": _vm.sortDesc,
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        items: _vm.getWorkerPayers,
                        fields: _vm.payersFields,
                        busy: _vm.tableLoading,
                        "sticky-header": "90vh",
                        "primary-key": "payer_id",
                        "show-empty": "",
                        emptyHtml: _vm.emptyTableListMessage("payers"),
                        striped: "",
                      },
                      on: {
                        "update:sortDesc": function ($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function ($event) {
                          _vm.sortDesc = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "table-busy",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary my-2",
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle mr-2",
                                    }),
                                    _c("strong", [
                                      _vm._v("Fetching payers for you..."),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "cell(payer)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.name || "-") + " "),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(website)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.website || "-") + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(phone)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.telephone || "-") + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(fax)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " + _vm._s(data.item.fax || "-") + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(address)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(data.item.full_address || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(assigned_on)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(data.item.assigned_on)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1065572008
                      ),
                    }),
                  ],
                  1
                ),
                _vm.payers.length > 0
                  ? _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                        "aria-controls": "payersTable",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }