var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0" },
    [
      _c(
        "div",
        { staticStyle: { width: "40vh" } },
        [
          !_vm.practicesLoading
            ? _c(
                "b-input-group",
                { staticClass: "search-group mb-4" },
                [
                  _c("div", { staticClass: "mb-2" }, [_vm._v("Filter By")]),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.practices,
                      "track-by": "practice_id",
                      label: "name",
                      "show-labels": false,
                      placeholder: "Select a practice",
                    },
                    model: {
                      value: _vm.selectedPractice,
                      callback: function ($$v) {
                        _vm.selectedPractice = $$v
                      },
                      expression: "selectedPractice",
                    },
                  }),
                ],
                1
              )
            : _c("div", [_c("b-spinner")], 1),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex my-5" }, [
        _c(
          "div",
          { staticClass: "stat-box mr-3" },
          [
            _c(
              "b-iconstack",
              { staticClass: "mr-4 ml-1", staticStyle: { color: "#3591EB" } },
              [
                _c("b-icon", {
                  staticStyle: { color: "#F3F8FD" },
                  attrs: { icon: "circle-fill", scale: "2.2" },
                }),
                _c("b-icon", { attrs: { icon: "circle", scale: "2.2" } }),
                _c("b-icon", { attrs: { icon: "hourglass", scale: "1" } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column justify-content-center" },
              [
                _c("div", { staticClass: "avo-text-light neg-margin" }, [
                  _vm._v("Avg. Time to Close"),
                ]),
                _c("div", { staticClass: "stat-text" }, [
                  _vm._v(_vm._s(_vm.stats.avgTimeToClose) + " "),
                  _c("span", { staticClass: "stat-unit-text" }, [
                    _vm._v("days"),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "stat-box mr-3" },
          [
            _c(
              "b-iconstack",
              { staticClass: "mr-4 ml-1", staticStyle: { color: "#3591EB" } },
              [
                _c("b-icon", {
                  staticStyle: { color: "#F3F8FD" },
                  attrs: { icon: "circle-fill", scale: "2.2" },
                }),
                _c("b-icon", { attrs: { icon: "circle", scale: "2.2" } }),
                _c("b-icon", { attrs: { icon: "check2-square", scale: "1" } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column justify-content-center" },
              [
                _c("div", { staticClass: "avo-text-light neg-margin" }, [
                  _vm._v("Avg. Claims Worked"),
                ]),
                _c("div", { staticClass: "stat-text" }, [
                  _vm._v(_vm._s(_vm.stats.avgClaimsWorked) + " "),
                  _c("span", { staticClass: "stat-unit-text" }, [
                    _vm._v("per day"),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "stat-box mr-3" }, [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-iconstack",
                { staticClass: "mr-4 ml-1", staticStyle: { color: "#3591EB" } },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#F3F8FD" },
                    attrs: { icon: "circle-fill", scale: "2.2" },
                  }),
                  _c("b-icon", { attrs: { icon: "circle", scale: "2.2" } }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    color: "#3591EB",
                    position: "absolute",
                    top: "-1px",
                    left: "6.5px",
                  },
                },
                [_vm._v("$")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-content-center" },
            [
              _c("div", { staticClass: "avo-text-light neg-margin" }, [
                _vm._v("Avg. Payments Collected"),
              ]),
              _c("div", { staticClass: "stat-text" }, [
                _vm._v(_vm._s(_vm.stats.avgPaymentsCollected) + "%"),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "stat-box mr-3" },
          [
            _c(
              "b-iconstack",
              { staticClass: "mr-4 ml-1", staticStyle: { color: "#3591EB" } },
              [
                _c("b-icon", {
                  staticStyle: { color: "#F3F8FD" },
                  attrs: { icon: "circle-fill", scale: "2.2" },
                }),
                _c("b-icon", { attrs: { icon: "circle", scale: "2.2" } }),
                _c("b-icon", { attrs: { icon: "x-octagon", scale: "1" } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column justify-content-center" },
              [
                _c("div", { staticClass: "avo-text-light neg-margin" }, [
                  _vm._v("Unresolved Claims"),
                ]),
                _c("div", { staticClass: "stat-text" }, [
                  _vm._v(_vm._s(_vm.stats.unresolvedClaims) + "%"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "stat-box" },
          [
            _c(
              "b-iconstack",
              { staticClass: "mr-4 ml-1", staticStyle: { color: "#3591EB" } },
              [
                _c("b-icon", {
                  staticStyle: { color: "#F3F8FD" },
                  attrs: { icon: "circle-fill", scale: "2.2" },
                }),
                _c("b-icon", { attrs: { icon: "circle", scale: "2.2" } }),
                _c("b-icon", { attrs: { icon: "slash-circle", scale: "1" } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column justify-content-center" },
              [
                _c("div", { staticClass: "avo-text-light neg-margin" }, [
                  _vm._v("Claims Denied"),
                ]),
                _c("div", { staticClass: "stat-text" }, [
                  _vm._v(_vm._s(_vm.stats.deniedClaims) + "%"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.claimsWorkedChartLoading } },
                [
                  _c("b-card", { staticClass: "summary-card shadow mb-4" }, [
                    _c("div", { staticClass: "summary-card-header-txt mb-3" }, [
                      _vm._v(" Claims Worked "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "chart-container",
                        staticStyle: { height: "350px" },
                      },
                      [
                        _c("claims-worked-line-chart", {
                          attrs: {
                            chartData: _vm.claimsWorkedChartData,
                            styles: {
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.currentIssuesTableLoading } },
                [
                  _c(
                    "b-card",
                    { staticClass: "summary-card-table shadow mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between px-3 pb-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "summary-card-header-txt pl-1" },
                            [_vm._v(" Current Issue Claims ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "avo-text-light pr-2 mt-1 cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.$store.commit(
                                    "workers/setActiveTab",
                                    "WorkerIssues"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" View all Issues "),
                              _c("b-icon", {
                                attrs: { icon: "chevron-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("b-table", {
                        ref: "issueClaimsTable",
                        staticClass: "avo-table-embedded",
                        attrs: {
                          id: "pending-claims-table",
                          fields: _vm.currentIssuesFields(),
                          items: _vm.currentIssuesTableData,
                          "primary-key": "claim_id",
                          "show-empty": "",
                          "empty-text": "No current issue claims",
                          striped: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(claim_id)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _c("strong", [_vm._v(_vm._s(data.value))]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(issue_type)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(data.item.state.issue)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(reason)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      data.item.last_issue_action
                                        ? data.item.last_issue_action
                                            .description
                                        : "N/A"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(work_status)",
                            fn: function (data) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      data.item.last_action_type_label
                                        ? data.item.last_action_type_label
                                        : "N/A"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-card", { staticClass: "summary-card mb-4 pl-3" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "claims-billed-text mr-5" }, [
                    _vm._v(_vm._s(_vm.stats.claimsBilledCount)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column justify-content-center",
                    },
                    [
                      _c("div", { staticClass: "avo-medium-text" }, [
                        _vm._v("Claims Billed"),
                      ]),
                      _c("div", { staticClass: "avo-text-light" }, [
                        _vm._v(
                          "$" + _vm._s(_vm.stats.claimsBilledAmount) + " Value"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-card", { staticClass: "summary-card mb-4 pl-3" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "claims-paid-text mr-5" }, [
                    _vm._v(_vm._s(_vm.stats.claimsPaidCount)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column justify-content-center",
                    },
                    [
                      _c("div", { staticClass: "avo-medium-text" }, [
                        _vm._v("Claims Paid"),
                      ]),
                      _c("div", { staticClass: "avo-text-light" }, [
                        _vm._v(
                          "$" + _vm._s(_vm.stats.claimsPaidAmount) + " Value"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-card", { staticClass: "summary-card mb-4 pl-3" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "claims-outstanding-text mr-5" }, [
                    _vm._v(_vm._s(_vm.stats.claimsOutstandingCount)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column justify-content-center",
                    },
                    [
                      _c("div", { staticClass: "avo-medium-text" }, [
                        _vm._v("Claims Oustanding"),
                      ]),
                      _c("div", { staticClass: "avo-text-light" }, [
                        _vm._v(
                          "$" +
                            _vm._s(_vm.stats.claimsOutstandingAmount) +
                            " Value"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.worklogListLoading } },
                [
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pr-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between px-2 pb-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "summary-card-header-txt" },
                            [_vm._v(" Work Log ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "avo-text-light pr-1 mt-1 cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.$store.commit(
                                    "workers/setActiveTab",
                                    "WorkerWorklog"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" View all "),
                              _c("b-icon", {
                                attrs: { icon: "chevron-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("work-log", {
                        attrs: {
                          logData: _vm.worklogArr,
                          showClaimId: true,
                          hideSearchGroup: true,
                          hidePagination: true,
                          embedded: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-card", { staticClass: "summary-card shadow mb-4 pr-2" }, [
                _c("div", { staticClass: "summary-card-header-txt" }, [
                  _vm._v(" Claims by Stage "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { height: "400px" },
                  },
                  [
                    _vm.claimsByStageChartLoading
                      ? _c(
                          "div",
                          { staticClass: "spinner-container" },
                          [_c("b-spinner")],
                          1
                        )
                      : _vm._e(),
                    _c("horizontal-bar-chart", {
                      attrs: {
                        chartData: _vm.claimsByStageChartData,
                        styles: {
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "b-card",
                {
                  staticClass: "summary-card shadow mb-4",
                  staticStyle: { height: "400px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-start" },
                    [
                      _c("div", { staticClass: "summary-card-header-txt" }, [
                        _vm._v(" Age of Claims "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "bar-chart-container" },
                    [
                      _vm.claimsByAgeChartLoading
                        ? _c(
                            "div",
                            { staticClass: "spinner-container" },
                            [_c("b-spinner")],
                            1
                          )
                        : _vm._e(),
                      _c("bar-chart", {
                        attrs: {
                          chartData: _vm.claimsByAgeChartData,
                          styles: {
                            height: "100%",
                            width: "100%",
                            marginRight: "0px",
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.topIssuesChartLoading } },
                [
                  _c(
                    "b-card",
                    { staticClass: "summary-card shadow mb-4 pb-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "summary-card-header-txt mb-4" },
                        [_vm._v(" Top Claim Issues ")]
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "pr-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "chart-container" },
                                [
                                  _c("donut-chart", {
                                    attrs: {
                                      chartData: _vm.topIssuesChartData,
                                      loaded: !_vm.topIssuesChartLoading,
                                      hideLegend: true,
                                      cutoutPercent: 55,
                                      styles: {
                                        width: "80%",
                                        position: "relative",
                                      },
                                    },
                                  }),
                                  !_vm.topIssuesChartLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "absolute-centering" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-center justify-content-center",
                                            },
                                            [
                                              _vm.totalIssuesClaims != 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#38455D",
                                                        "font-size": "20px",
                                                        "font-weight": "500",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalIssuesClaims.toLocaleString()
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#38455D",
                                                        "font-size": "18px",
                                                      },
                                                    },
                                                    [_vm._v("No Issues")]
                                                  ),
                                              _vm.totalIssuesClaims != 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "small-head-txt",
                                                    },
                                                    [_vm._v("Total")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("b-col", { attrs: { cols: "6" } }, [
                            !_vm.topIssuesChartLoading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-center h-100 pr-5",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mb-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "small-head-txt" },
                                              [_vm._v("Bad Insurance ID")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "small-head-txt-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.topIssuesChartData
                                                      .datasets[0].data[0]
                                                      ? _vm.topIssuesChartData.datasets[0].data[0].toLocaleString()
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "thin-bar-background",
                                            }),
                                            _c("div", {
                                              staticClass: "thin-bar-overlay",
                                              style:
                                                _vm.getThinBarStyle(
                                                  "bad_insurance_id"
                                                ),
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mb-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "small-head-txt" },
                                              [_vm._v("Coding")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "small-head-txt-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.topIssuesChartData
                                                      .datasets[0].data[1]
                                                      ? _vm.topIssuesChartData.datasets[0].data[1].toLocaleString()
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "thin-bar-background",
                                            }),
                                            _c("div", {
                                              staticClass: "thin-bar-overlay",
                                              style:
                                                _vm.getThinBarStyle("coding"),
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mb-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "small-head-txt" },
                                              [_vm._v("Late Payment")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "small-head-txt-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.topIssuesChartData
                                                      .datasets[0].data[2]
                                                      ? _vm.topIssuesChartData.datasets[0].data[2].toLocaleString()
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "thin-bar-background",
                                            }),
                                            _c("div", {
                                              staticClass: "thin-bar-overlay",
                                              style:
                                                _vm.getThinBarStyle(
                                                  "late_payment"
                                                ),
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "small-head-txt" },
                                              [_vm._v("Miscellaneous")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "small-head-txt-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.topIssuesChartData
                                                      .datasets[0].data[3]
                                                      ? _vm.topIssuesChartData.datasets[0].data[3].toLocaleString()
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "thin-bar-background",
                                            }),
                                            _c("div", {
                                              staticClass: "thin-bar-overlay",
                                              style:
                                                _vm.getThinBarStyle(
                                                  "miscellaneous"
                                                ),
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mb-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "small-head-txt" },
                                              [_vm._v("Under paid")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "small-head-txt-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.topIssuesChartData
                                                      .datasets[0].data[4]
                                                      ? _vm.topIssuesChartData.datasets[0].data[4].toLocaleString()
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "thin-bar-background",
                                            }),
                                            _c("div", {
                                              staticClass: "thin-bar-overlay",
                                              style:
                                                _vm.getThinBarStyle(
                                                  "underpaid"
                                                ),
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.taskListLoading
                ? _c(
                    "b-card",
                    {
                      staticClass: "summary-card shadow mb-4 pr-2",
                      staticStyle: { "min-height": "300px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between px-2 pb-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "summary-card-header-txt" },
                            [_vm._v(" Recent Tasks ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "avo-text-light pr-1 mt-1 cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.$store.commit(
                                    "workers/setActiveTab",
                                    "WorkerTasks"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" View all "),
                              _c("b-icon", {
                                attrs: { icon: "chevron-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "spinner-container" },
                        [_c("b-spinner")],
                        1
                      ),
                    ]
                  )
                : _c("task-list", {
                    staticClass: "mb-4",
                    attrs: { taskData: _vm.taskArr, screen: false },
                    on: {
                      viewAllClicked: function ($event) {
                        return _vm.$store.commit(
                          "workers/setActiveTab",
                          "WorkerTasks"
                        )
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }